/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header"
import "../scss/layout.scss"
import Footer from "./Footer"
// import Sale from "./Sale"
import SaleSticky from "./SaleSticky"

const Layout = ({ children, hideSearch = false, location, noQuote = false, noSaleSticky }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div>
      <Header siteTitle={data.site.siteMetadata.title} hideSearch={hideSearch} location={location} />
      <main>{children}</main>
      <Footer location={location} noQuote={noQuote} />
      {/* <Sale location={location} /> */}
      { !noSaleSticky &&  <SaleSticky location={location} /> }
      
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
