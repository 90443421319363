import React, { useState } from "react"
import styled from 'styled-components'
import ArrowIcon from '../Icons/ArrowIcon'
import SummerSaleSticky from "../../images/sale/summer-sale-sticky.png"
import { Link } from "gatsby"


const StcikyWrapper = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
    display: inline-block;
    cursor: pointer;
    transition: all .5s;
    transform: translateX(-100%);
    width: 180px;
    @media (min-width: 1200px) {
        width: 250px;
    }
    @media (min-width: 1400px) {
        width: 300px;
    }
    &.show{
        transform: translateX(0);
    .toggle{
        border-radius: 100%;
      svg{
        transform:scaleX(-1);
      }
    }
  } 
`

const StickyToggleOpener = styled.button`
    background: #F05801;
    border: 0;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    top: 0;
    z-index: 1;
    box-sizing: border-box;
    padding: 0;
    transition: all .5s;
    border-radius: 0 100% 100% 0;
    height: 30px;
    width: 30px;
    right: -30px;
    @media (min-width: 992px) {
        height: 40px;
        width: 40px;
        right: -40px;
    }
    @media (min-width: 992px) {
        &:hover{
            background: #000;
        }
    }
    svg{
        fill:#fff;        
    }
`

const SaleStickyImage = styled(Link)`
    margin:0;
    outline:none;
`


const SaleSticky = ({ location }) => {
    const [showSticky, setShowSticky] = useState(false)   
    const ToggleHelp = () => {
      setShowSticky(!showSticky);
    }
    return (
        <StcikyWrapper location={location} className={showSticky ? '' : 'show'}>
            <StickyToggleOpener type="button" className="toggle" onClick={ToggleHelp}><ArrowIcon /></StickyToggleOpener>
            <SaleStickyImage to="/specials"><img src={SummerSaleSticky} alt="Premier Metal Structures" /></SaleStickyImage>
        </StcikyWrapper>
    )
}
export default SaleSticky