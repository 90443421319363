import React from 'react'
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  0% {
    transform: rotate(0) skew(1deg);
  }
  10% {
    transform rotate(-16deg) skew(1deg);
	}
	20% {
	  transform rotate(16deg) skew(1deg);
	}
	30% {
	  transform rotate(-16deg) skew(1deg);
	}
	40% {
	  transform rotate(16deg) skew(1deg);
	}
	50% {
	  transform rotate(0) skew(1deg);
	}
	100% {
	  transform rotate(0) skew(1deg);
	}
`;

const BaseButton = styled.span`
	display: inline-block;	
	text-align:center;
	padding:12px 24px;
	font-size:16px;
	font-weight:700;
	background: #020304;	
	border: 1px solid #020304;
	color:#fff;
	position:relative;

    vertical-align: middle;
    transform: perspective(1px) translateZ(0);    
    transition-property: color;    
    transition-duration: 0.3s;
	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: #fff;
		transform: scaleX(0);
		transform-origin: 0 50%;
		transition-property: transform;
		transition-duration: 0.3s;
		transition-timing-function: ease-out;
	}
	> svg{
		height:20px;
		display:inline-block;
		vertical-align:middle;
		fill:#fff;
		+ .text{
			margin-left:5px;
		}
	}
	> .text{
		line-height:20px;
		display:inline-block;
		vertical-align:middle;
	}
	&:hover{
		border-color:#ddd;	
		color:#030504;		
		& svg {
			fill: #030504 !important;
			path {
				fill: #030504 !important;
			}
		}
		&:before{
			transform: scaleX(1);
		}
	}
`

const Rotate = styled.span`
  display: inline-block;
  animation: ${rotate} 1s infinite ease-in-out;
  & svg {
  	fill: #fff;
  	vertical-align: middle;
  }
`;


const DefaultButton = (props) => {
	const { icon, text, animation, backIcon = false } = props;
	if (animation) {
		return (
			<BaseButton className="btn">
				<Rotate>{icon}</Rotate> <span className="text">{text}</span>
			</BaseButton>
		)
	}
	if (backIcon) {
		return (
			<BaseButton className="btn">
				<span className="text">{text}</span> {icon}
			</BaseButton>
		)
	}

	return (
		<BaseButton className="btn">
			{icon} <span className="text">{text}</span>
		</BaseButton>
	)
}

export default DefaultButton