import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import RequestQuote from "../../components/RequestQuote"
import FacebookIcon from "../../components/Icons/FacebookIcon"
import InstagramIcon from "../../components/Icons/InstagramIcon"
import MapPointerIcon from "../../components/Icons/MapPointerIcon"
import PaperPlanIcon from "../../components/Icons/PaperPlanIcon"
import PhoneLineIcon from "../../components/Icons/PhoneLineIcon"
import BreakpointDown from "../../components/Media/BreakpointDown"

const FooterWrapper = styled.footer`
  margin:0;
  padding:30px 0 0;
  background-color:#fff;
  border-top:1px solid #ddd;  
`
const AddressWrapp = styled.div`
  color:#020304;
	& span {
    margin-right: 10px;
    vertical-align: text-top;
    display: inline-block;
  }
  & a{
    display:block;
    margin-bottom:15px;
    color:#020304;
    &:hover{
      color:#020304;
    }

  }
`
const MenuGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(5, auto);
  justify-content: space-between; 
  
	${BreakpointDown.lg`    
  grid-template-columns: repeat(3, minmax(0, 1fr));
  justify-content: space-evenly;
  `}
  
  ${BreakpointDown.md`    
    grid-template-columns: repeat(2, minmax(0, 1fr));
  `} 
  
  ${BreakpointDown.sm`        
    grid-template-columns: repeat(1, auto);	
    justify-content: inherit;
    ul{
      border-bottom: 1px solid #ddd;      
    }
  `}

  & h3{
    margin-bottom:20px;
    font-weight:700;
    color:#444;
    font-size:20px;
    font-family: 'Roboto', serif;

  }
`
const MenuList = styled.ul`
  list-style:none;
  margin:0 0 15px;
  padding:0;
`
const MenuItem = styled.li`
	& a {
    padding:5px 0;
		color: rgba(102, 102, 102, 0.7);
		&:hover {
			color:#020304;
		}
	}
`
const FooterTop = styled.div`
  padding:30px 0;
`
const FooterMiddle = styled.div`
  padding:30px 0;
`
const FooterBottom = styled.div`
  color:rgba(102,102,102,0.7);
  border-top:1px solid #ddd;
  padding:30px 0;
`
const CopyrightGrid = styled.div`
  display:grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  align-items: center;
  ${BreakpointDown.sm`    
    justify-content: center;
    grid-template-columns: repeat(1, auto);
  `}
`
const Copyright = styled.div`
  font-size:14px;
  ${BreakpointDown.sm`    
    margin-bottom:10px;
  `}
`
const SocialIcon = styled.ul`
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;  
  ${BreakpointDown.sm`    
    justify-content: inherit;
  `}
  
`
const SocialItem = styled.li`
  list-style: none;    
  margin:0 10px;
  a{
    width: 40px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #DEDEDE;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
      fill:#9B9EA2;
    }
    &:hover{
      border-color:#020304;
      svg{
        fill:#020304;
      }
    }
  }
`

const Footer = ({ location, noQuote = false }) => (
  <FooterWrapper>
    {!noQuote && <FooterTop>
      <div className="container">
        <RequestQuote location={location} />
      </div>
    </FooterTop>}
    <FooterMiddle>
      <div className="container">
        <MenuGrid>
          <MenuList>
            <h3>Our Buildings</h3>
            {/* <MenuItem><Link to='/metal-carports'>Carports</Link></MenuItem> */}
            <MenuItem><Link to='/metal-garages'>Garages</Link></MenuItem>
            <MenuItem><Link to='/metal-barns'>Barns</Link></MenuItem>
            <MenuItem><Link to='/metal-rv-covers'>RV Covers</Link></MenuItem>
          </MenuList>

          <MenuList>
            <h3>Important Links</h3>
            <MenuItem><Link to="/about-us">About Premier</Link></MenuItem>
            <MenuItem><Link to='/rto'>Financing Options</Link></MenuItem>
            <MenuItem><Link to='/faq'>Frequently Asked Questions</Link></MenuItem>
            <MenuItem><Link to='/blog'>Blog</Link></MenuItem>
            <MenuItem><Link to='/contact-us'>Contact us</Link></MenuItem>
          </MenuList>

          <MenuList>
            <h3>Buying Guide</h3>
            <MenuItem><Link to="/metal-building-roof-styles">Metal Building Roof Guide</Link></MenuItem>
            <MenuItem><Link to="/site-preparation">Site Preparation Guide</Link></MenuItem>
            <MenuItem><Link to="/order-process">Order/Installation Process</Link></MenuItem>
          </MenuList>

          <MenuList>
            <h3>Other Links</h3>
            <MenuItem><Link to="/privacy-policy">Privacy Policy</Link></MenuItem>
            <MenuItem><Link to="/terms-of-use">Terms of Use</Link></MenuItem>
            <MenuItem><Link to="/sitemap">Sitemap</Link></MenuItem>
          </MenuList>

          <AddressWrapp>
            <h3>Our Address</h3>
            <p><span><MapPointerIcon fill="#666" /></span>998 West Pine Street Mount Airy NC 27030</p>
            <a href="tel:8776005980"><span><PhoneLineIcon fill="#666" /></span>(877) 600-5980</a>
            <a href="mailto:sales@premiermetalstructures.com"><span><PaperPlanIcon fill="#666" /></span>sales@premiermetalstructures.com</a>
          </AddressWrapp>
        </MenuGrid>
      </div>
    </FooterMiddle>
    <FooterBottom>
      <div className="container">
        <CopyrightGrid>
          <Copyright>© {new Date().getFullYear()} Premier Metal Structures. All rights reserved.</Copyright>
          <SocialIcon>
            <SocialItem>
              <Link className="item-link" to="https://www.facebook.com/premiermetalstructures/"><FacebookIcon /></Link>
            </SocialItem>
            <SocialItem>
              <Link className="item-link" to="https://www.instagram.com/premiermetalstructures/"><InstagramIcon /></Link>
            </SocialItem>
          </SocialIcon>
        </CopyrightGrid>
      </div>
    </FooterBottom>
  </FooterWrapper>
)

export default Footer
