import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import DefaultButton from "../button/DefaultButton"
import DefaultOutlineButton from "../button/DefaultOutlineButton"
import PhoneIcon from "../Icons/PhoneIcon"
import QouteIcon from "../Icons/QouteIcon"

import BreakpointDown from "../../components/Media/BreakpointDown"
import QuotePopup from '../QuotePopup'

const RequestQuoteBg = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "request-quote.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
}

const SectionBg = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	.gatsby-image-wrapper {
		width: 100%;
		height: 100%;
	}
`
const SectionRequest = styled.div`
	display:flex;
	flex-wrap:wrap;
	align-items: center;
	justify-content: space-between;	
	background-repeat: no-repeat;
	background-position: left top;
	position:relative;
	border: 1px solid #ddd;
	padding:60px 60px 60px 0;
	${BreakpointDown.lg`
		background-size: cover;
		flex-direction:column;
		border: none;
		padding:30px 0 30px;
	`}	
	&:after{
		content:'';
		position:absolute;
		top:0;
		right:0;
		bottom:0;
		left:0;
		background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 70%);
		background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%);
		background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%);		
		${BreakpointDown.lg`
			background:rgba(255,255,255, 0.9);
				
		`}
	}
`
const TextWrap = styled.div`
		font-size:28px;
		color:#020304;
		font-family: 'IBM Plex Serif', serif;
		font-weight:700;
		max-width:590px;
		width:100%;
		position: relative;
		z-index: 1;
		padding:30px 60px;
		line-height:40px;
		background-color:rgba(255,255,255, 0.9);
		${BreakpointDown.sm`
			padding:30px;
		`}
		${BreakpointDown.lg`
			background-color:transparent;
			text-align:center;
		`}

	`
const ButtonWrap = styled.div`
	position:relative;
	z-index:1;
	display: block;
	& a{
		display:block;
		& .btn{
			display:block;
			font-size:20px;
			padding:16px 40px;			
		}
		+ a{
			margin-top:20px;
		}
	} 
	
	${BreakpointDown.lg`				
		display:flex;
		align-items:center;
		margin:0 -10px;
		& a{
			padding:0 10px;
			+ a{
				margin-top:0;
			}
		}
	`}
	${BreakpointDown.sm`				
		display:block;
		margin:0 -10px;
		& a{
			padding:0 10px;
			+ a{
				margin-top:20px;
			}
		}
	`}
	& div {
		margin-top: 10px;
		width: 100%;
		& .btn {
			width: 100%;
		}
		&:hover {
			cursor: pointer;
		}
		@media(max-width: 990px) {
			margin: 10px;
			width: calc(100% - 20px);
		}
	}
	
`

class RequestQuote extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isVisibleQuote: false,
		}
		this.showQuote = this.showQuote.bind(this)
    this.onClosePopup = this.onClosePopup.bind(this)
	}
	showQuote() {
    this.setState({
      isVisibleQuote: true,
    })
    if (typeof window !== `undefined`) {
      document.body.classList.add('popup-open')
    }
  }
  onClosePopup() {
    this.setState({
      isVisibleQuote: false,
    })
    if (typeof window !== `undefined`) {
      document.body.classList.remove('popup-open')
    }
  }
	render() {
		const { isVisibleQuote } = this.state
		const { location } = this.props;
		return(
			<>
				<SectionRequest>
					<SectionBg>
						<RequestQuoteBg />
					</SectionBg>
					<TextWrap>
						Gain valuable insights and get a free quote! Call today!
					</TextWrap>
					<ButtonWrap>
						<a href="tel:8776005980"><DefaultButton icon={<PhoneIcon />} text="(877) 600-5980" /></a>
						<div onClick={this.showQuote}>
							<DefaultOutlineButton icon={<QouteIcon />} text="Request A Quote" />
						</div>
					</ButtonWrap>
				</SectionRequest>
				<QuotePopup
				  isVisible={isVisibleQuote}
					onClose={this.onClosePopup}
					location={location}
				/>
			</>
		)
	}
}

export default RequestQuote